import React, { useEffect, useState } from "react";
import Layouts from "./Layouts";
import SubHeader from "../components/SubHeader";
import banner from "../images/banner1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo.png";
import CryptoJS from "crypto-js";

const Booking = () => {
  const [IsLoaderOpenPayment, setIsLoaderOpenPayment] = useState(false);
  const [checkformpup, setCheckformpup] = useState(true);
  const [razorpayScriptLoaded, setRazorpayScriptLoaded] = useState(false);

  useEffect(() => {
    // Dynamically load Razorpay script
    const loadRazorpayScript = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => setRazorpayScriptLoaded(true);
      script.onerror = () => toast.error("Failed to load Razorpay script.");
      document.body.appendChild(script);
    };

    loadRazorpayScript();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    people: "",
    tourdate: "",
    tourEnddate: "",
    accommodation: "",
    aadhar: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.aadhar) {
      newErrors.aadhar = "Aadhar number is required.";
    } else if (!/^\d{12}$/.test(formData.aadhar)) {
      newErrors.aadhar = "Aadhar number must be exactly 12 digits.";
    }
    // if (!formData.people.trim()) {
    //     newErrors.people = "Number of people is required.";
    // } else if (isNaN(formData.people) || formData.people < 1) {
    //     newErrors.people = "Enter a valid number greater than 0.";
    // }
    if (!formData.tourdate) {
      newErrors.tourdate = "Tour date is required.";
    }
    if (!formData.tourEnddate) {
      newErrors.tourEnddate = "Tour end date is required.";
    }
    if (!formData.accommodation.trim()) {
      newErrors.accommodation = "Accommodation is required.";
    }
    // if (!formData.message.trim()) {
    //     newErrors.message = "Message is required.";
    // }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //setFormData((prev) => ({ ...prev, [name]: value }));
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "tourdate" ? { tourEnddate: "" } : {}), // Reset tourEnddate if tourdate changes
    }));
  };

  const finaldata = {
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    aadhar: formData.aadhar,
    people:
      formData.accommodation == "2500" || formData.accommodation == "4500"
        ? 1
        : formData.accommodation == "15000" ||
          formData.accommodation == "18000" ||
          formData.accommodation == "12500" ||
          formData.accommodation == "25000" ||
          formData.accommodation == "8000"
        ? 3
        : "",
    tourdate: formData.tourdate,
    tourEnddate: formData.tourEnddate,
    accommodation: formData.accommodation,
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      // Call booking API
      const response = await fetch(
        "https://api.shivayadventure.com/api/bookingTent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(finaldata),
        }
      );
      const data = await response.json();

      if (response.ok && razorpayScriptLoaded && data.orderId) {
        const amount = parseInt(finaldata.accommodation) * 100; // Amount in paise

        const options = {
          key: "rzp_live_OXyz2sHYf9nLQP",
          amount: amount,
          name: formData.name,
          description: `Booking for ${finaldata.people} people`,
          image: logo,
          order_id: data.orderId, // Pass the Razorpay orderId
          handler: function (paymentResponse) {
            verifyPayment(paymentResponse, finaldata); // Verify the payment
          },
          prefill: {
            name: finaldata.name,
            email: finaldata.email,
            contact: finaldata.phone,
          },
          theme: { color: "#F37254" },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
        setSuccessMessage("Please complete your payment.");
      } else {
        toast.error(data.error || "Failed to initialize payment.");
        if (
          data.error ==
          "All slots for this date range and accommodation are already booked."
        ) {
          setIsLoaderOpenPayment(true);
        }
      }
    } catch (error) {
      setErrorMessage("Error submitting form: " + error.message);
    }
  };

  // Verify payment
  const verifyPayment = async (paymentResponse, finaldata) => {
    console.log("formDataformData");
    try {
      const paymentData = {
        payment_id: paymentResponse.razorpay_payment_id,
        order_id: paymentResponse.razorpay_order_id,
        signature: paymentResponse.razorpay_signature,
        bookingDetails: finaldata,
        ...finaldata,
      };

      const razorpayOrderId = paymentData.order_id;
      const razorpayPaymentId = paymentData.payment_id;
      const razorpaySignature = paymentData.signature;

      const signatureString = razorpayOrderId + "|" + razorpayPaymentId;

      // Your secret key from Razorpay to verify the signature
      const secretKey = "Oxdojg2187VZGhG2TQPW4h8j";

      // Generate the HMAC SHA256 signature using crypto-js
      const generatedSignature = CryptoJS.HmacSHA256(
        signatureString,
        secretKey
      ).toString(CryptoJS.enc.Hex);

      if (generatedSignature !== razorpaySignature) {
        throw new Error("Invalid payment signature.");
      }

      const response = await fetch(
        "https://api.shivayadventure.com/api/verifyPayment",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(paymentData),
        }
      );
      const data = await response.json();

      if (response.ok) {
        toast.success("Payment successful and booking confirmed!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          people: "",
          tourdate: "",
          tourEnddate: "",
          accommodation: "",
          aadhar: "",
          message: "",
        });
        sessionStorage.setItem("checkform", "false");
        setSuccessMessage("");
      } else {
        toast.error(data.error || "Payment verification failed.");
        setFormData({
          name: "",
          email: "",
          phone: "",
          people: "",
          tourdate: "",
          tourEnddate: "",
          accommodation: "",
          aadhar: "",
          message: "",
        });
        sessionStorage.setItem("checkform", "false");
        setSuccessMessage("");
      }
    } catch (error) {
      toast.error("Error verifying payment: " + error.message);
    }
  };

  const handleclosePopPametfull = () => {
    setIsLoaderOpenPayment(false);
  };

  const getWoodenCottagePrice = () => {
    const specialDates = [
      "2025-01-13",
      "2025-01-14",
      "2025-01-29",
      "2025-02-03",
      "2025-02-12",
      "2025-02-26",
    ]; // Adjust the format to match your date input
    if (
      specialDates.includes(formData.tourdate) ||
      specialDates.includes(formData.tourEnddate)
    ) {
      return "25000";
    }
    return "15000";
  };
  const getDormetryTentPrice = () => {
    const specialDates = [
      "2025-01-13",
      "2025-01-14",
      "2025-01-29",
      "2025-02-03",
      "2025-02-12",
      "2025-02-26",
    ]; // Adjust the format to match your date input
    if (
      specialDates.includes(formData.tourdate) ||
      specialDates.includes(formData.tourEnddate)
    ) {
      return "4500";
    }
    return "2500";
  };
  return (
    <Layouts>
      <SubHeader
        banner={banner}
        heading="Book Accommodation in Maha Kumbh Mela - 2025"
      />
      <section className="blog-details-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="bd-text">
                <div className="bd-more-text">
                  <h4>Book Accommodation in Maha Kumbh Mela - 2025</h4>
                  <p>
                    Amount to be paid as per the following:
                    <b>...details of payment... </b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <form
                onSubmit={handleSubmit}
                className="comment-form contact-form"
              >
                {successMessage && (
                  <p className="success-text">{successMessage}</p>
                )}
                {errorMessage && <p className="error-text">{errorMessage}</p>}
                <div className="row">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error-text">{errors.name}</p>}
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-text">{errors.email}</p>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="text"
                      placeholder="Phone Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <p className="error-text">{errors.phone}</p>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <input
                      type="number"
                      name="aadhar"
                      placeholder="Aadhar Number"
                      value={formData.aadhar}
                      onChange={handleChange}
                    />
                    {errors.aadhar && (
                      <p className="error-text">{errors.aadhar}</p>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <h4 className="subheading">Tour start date</h4>
                    <input
                      type="date"
                      name="tourdate"
                      value={formData.tourdate}
                      onChange={handleChange}
                      min={new Date().toISOString().split("T")[0]}
                    />
                    {errors.tourdate && (
                      <p className="error-text">{errors.tourdate}</p>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <h4 className="subheading">Tour end date</h4>
                    <input
                      type="date"
                      name="tourEnddate"
                      value={formData.tourEnddate}
                      onChange={handleChange}
                      min={
                        formData.tourdate
                          ? new Date(
                              new Date(formData.tourdate).getTime() + 86400000
                            )
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      max={
                        formData.tourdate
                          ? new Date(
                              new Date(formData.tourdate).getTime() + 86400000
                            )
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled={formData.tourdate === ""}
                    />
                    {errors.tourEnddate && (
                      <p className="error-text">{errors.tourEnddate}</p>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <select name="accommodation" onChange={handleChange}>
                      <option value="" selected={formData.accommodation === ""}>
                        Select Accommodation
                      </option>
                      <option value={getWoodenCottagePrice()}>
                        Wooden cottages (₹{getWoodenCottagePrice()})
                      </option>
                      <option value={getDormetryTentPrice()}>
                        Dormitory tent (₹{getDormetryTentPrice()})
                      </option>
                    </select>
                    {errors.accommodation && (
                      <p className="error-text">{errors.accommodation}</p>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="number"
                      placeholder="Number of people"
                      name="people"
                      value={
                        formData.accommodation == "2500" ||
                        formData.accommodation == "4500"
                          ? 1
                          : formData.accommodation == "15000" ||
                            formData.accommodation == "18000" ||
                            formData.accommodation == "12500" ||
                            formData.accommodation == "25000" ||
                            formData.accommodation == "8000"
                          ? 3
                          : ""
                      }
                      disabled
                      onChange={handleChange}
                    />
                    <p
                      className="error-text"
                      style={{ color: "#df9906", fontWeight: "500" }}
                    >
                      {formData.accommodation == "2500" ||
                      formData.accommodation == "4500"
                        ? "Only one person is allowed."
                        : formData.accommodation == "15000" ||
                          formData.accommodation == "18000" ||
                          formData.accommodation == "12500" ||
                          formData.accommodation == "25000" ||
                          formData.accommodation == "8000"
                        ? "One couple and one child are allowed."
                        : ""}
                    </p>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="site-btn"
                      disabled={!razorpayScriptLoaded}
                    >
                      {razorpayScriptLoaded ? "Pay Now" : "Loading..."}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {IsLoaderOpenPayment ? (
        <div className="book_popup">
          <div className="book_popup_inner2">
            <button
              className="book_popup_inner_close"
              onClick={handleclosePopPametfull}
            >
              <i className="fa fa-close"></i>
            </button>

            <div className="book_popup_inner_right2">
              <h4>
                Slots are currently fully booked. For further inquiries or to
                secure a booking,
                <br></br>please contact
                <div className="cs-text">
                  {" "}
                  <ul>
                    <li>
                      <span>Phone:</span>Niraj Sharma{" "}
                      <small>(Marketing manegar)</small>
                      <br /> 8527130867, 6006527627
                    </li>
                  </ul>
                </div>
              </h4>
            </div>
          </div>
        </div>
      ) : null}
    </Layouts>
  );
};

export default Booking;
