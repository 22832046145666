import React from "react";
import Layouts from "./Layouts";
import SubHeader from "../components/SubHeader";
import banner from "../images/sub-aboutbanner.jpg";
import Aboutimg from "../images/about-img.png";

const About = () => {
  return (
    <Layouts>
      <SubHeader banner={banner} heading="About" />

      <section className="about-section spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>About Us </h2>
                <p className="f-para">
                  Welcome to Shivay Adventure, your trusted partner for an
                  unforgettable tent booking experience at Mahakumbh. Rooted in
                  the spirit of devotion and adventure, we strive to make your
                  stay comfortable, secure, and memorable amidst the sacred
                  energy of Mahakumbh.
                </p>
                <p className="f-para">
                  Our accommodations are designed to blend tradition with modern
                  amenities, ensuring you experience the best of both worlds.
                  From cozy interiors to essential facilities, we take care of
                  every detail to make your visit truly special.
                </p>
                <p className="f-para">
                  At Shivay Adventure, we understand the spiritual significance
                  of Mahakumbh. Our goal is to create an environment where you
                  can connect deeply with the divine and participate in the
                  vibrant cultural festivities with ease.
                </p>
                <p className="f-para">
                  Whether you are visiting for spiritual solace, cultural
                  exploration, or simply to witness this unique event, our
                  offerings cater to all your needs. We are dedicated to helping
                  you create cherished memories that last a lifetime.
                </p>
                <p className="f-para">
                  Thank you for choosing Shivay Adventure. Join us to embark on
                  a journey of spirituality, culture, and adventure at
                  Mahakumbh.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="about-pic">
                <img src={Aboutimg} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text">
                <h3> Significance of Maha Kumbh Mela 2025 </h3>
                <p>
                  Kumbh Mela, in Hinduism, is a religious pilgrimage that is
                  celebrated four times over a course of 12 years. The
                  geographical location of Kumbh Mela spans over four locations
                  in India and the Mela site keeps rotating between one of the
                  four pilgrimages on four sacred rivers as listed below:
                </p>

                <ul>
                  <li>
                    <span className="icon_check"></span>Haridwar (Ganges River)
                  </li>
                  <li>
                    <span className="icon_check"></span>In Haridwar,
                    Uttarakhand, on the banks of Ganges
                  </li>
                  <li>
                    <span className="icon_check"></span>In Ujjain, Madhya
                    Pradesh on the banks of Shipra
                  </li>
                  <li>
                    <span className="icon_check"></span>In Nashik, Maharashtra
                    on the banks of Godavari
                  </li>
                  <li>
                    <span className="icon_check"></span>In Prayagraj, Uttar
                    Pradesh, at the confluence of the Ganges, the Yamuna, and
                    the mythical invisible Sarasvati
                  </li>
                  <li>
                    <span className="icon_check"></span> Save Money{" "}
                  </li>
                  <li>
                    <span className="icon_check"></span> Safety and Security{" "}
                  </li>
                  <li>
                    <span className="icon_check"></span> Total Support{" "}
                  </li>
                  <li>
                    <span className="icon_check"></span> Connecting with God{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default About;
