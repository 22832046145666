import React, { useEffect, useState } from 'react'
import Layouts from './Layouts'
import HomeSlider from '../components/HomeSlider'
import AboutImage from "../images/about-img.png"
import { Link } from 'react-router-dom'
import bathimg from "../images/bath-img.png"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';



const images = [img1, img2,img3,img4,img5,img6,img7,img8];
const getRandomHeight = () => Math.floor(Math.random() * (600 - 400 + 1)) + 400;

const Home = () => {

const randomHeight = getRandomHeight();
    return (
        <>
            <Layouts>

                <HomeSlider />

                <section className="counter-section bg-gradient mb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="counter-text">
                                    <h3>
                                        "Maha Kumbh Mela Prayagraj (Allahabad) - 2025 Booking Started, Come OnHurry Up!" <Link to="/booking" className='book-btn blink'>Book Tent</Link></h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section class="team-member-section mt-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <h2>Accommodation</h2>
                                    <p>Explore our range of comfortable accommodations designed for your stay.</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                    <ImageList variant="masonry" cols={3} gap={8}>
                        {images.map((item) => (
                          <ImageListItem key={item} >
                            <img
                              srcSet={`${item}`}
                              src={`${item}`}
                              alt={item}
                              loading="lazy"
                              
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                     </section>

                <section className="home-about-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ha-pic">
                                    <img src={AboutImage} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ha-text">
                                    <h2>WELCOME TO Maha Kumbh MELA - 2025                        </h2>
                                    <p>Maha Kumbh Mela, listed on UNESCO's (The United Nations Educational, Scientific and Cultural Organization) Representative List of Intangible Cultural Heritage of Humanity, is considered as the world’s largest gathering of pilgrims and is a very vital event for Hinduism. This is the reason that a big number of Hindus from every corner of the world arrive at the Maha Kumbh Mela 2025 fair to bathe in the holy rivers, or a ritual meant to clean them of sin and bring pureness to their lives. The Kumbh Mela Prayagraj (Allahabad)attracts devotes in millions from all over the world.

                                    Maha Kumbh Mela held in four cities- 1) Nashik, 2) Prayagraj (Allahabad), 3) Ujjain, and 4) Haridwar. The Maha Kumbh Mela Prayagraj (Allahabad) is held in every 12 years on the banks of the 'Sangam' meeting of three holy rivers- 1) The Ganga, 2) The Yamuna and 3) The Saraswati. The Maha Kumbh Mela alternates between Nasik, Prayagraj (Allahabad), Ujjain or Haridwar every 3 years. But, Maha Kumbh in Prayagraj (Allahabad) (Prayag) is more critical compared to other Maha Kumbh because it contributes to mild.</p>

                                    <Link to="/about" className="ha-btn">Discover Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

          

                <section class="schedule-section spad bathing-dates">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <h2>Bathing Dates                        </h2>
                                    <p><span className='txtbg'>Do not miss anything topic about the event</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            {/* <div class="sbath_date_box"> */}
                            <div class="col-lg-5">
                                <div className='bath_date_box_left'>
                                    <img src={bathimg} />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div className='bath_date_box_right'>

                                    <div className=' bath-data'>
                                        <div class="date-tiles  ">
                                            <div class="tile">
                                                <div class="day-name">
                                                    PAUSH Purnima
                                                </div>
                                                <div class="day-date">
                                                    13
                                                </div>
                                                <div class="day-month">
                                                    JANUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                            <div class="tile">
                                                <div class="day-name">
                                                    MAKAR SANKRANTI
                                                </div>
                                                <div class="day-date">
                                                    14
                                                </div>
                                                <div class="day-month">
                                                    JANUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                            <div class="tile">
                                                <div class="day-name">
                                                    MAUNI AMAVASYA
                                                </div>
                                                <div class="day-date">
                                                    29
                                                </div>
                                                <div class="day-month">
                                                    JANUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                            <div class="tile">
                                                <div class="day-name">
                                                    BASANT PANCHAMI
                                                </div>
                                                <div class="day-date">
                                                    03
                                                </div>
                                                <div class="day-month">
                                                    FEBRUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                            <div class="tile">
                                                <div class="day-name">
                                                    MAGHI Purnima
                                                </div>
                                                <div class="day-date">
                                                    12
                                                </div>
                                                <div class="day-month">
                                                    FEBRUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                            <div class="tile">
                                                <div class="day-name">
                                                    MAHA SHIVARATRI
                                                </div>
                                                <div class="day-date">
                                                    26
                                                </div>
                                                <div class="day-month">
                                                    FEBRUARY
                                                </div>
                                                <div class="day-year">
                                                    2025
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </section>


                <section className="contact-section spad contact-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>Feel free to reach out </h2>
                                    <p>Get directions to our event center</p>
                                </div>
                                <div className="cs-text">
                                    <div className="ct-address">
                                        <span>Address:</span>
                                        <p>Someshwar ghat near by Delhi public school naini prayagraj. </p>
                                    </div>
                                    <ul>
                                    <li>
                                            <span>Phone:</span>
                                            Ashutosh Srivastava <small>(Manegar)</small><br></br> 8576956055<br></br>
                                            Niraj Sharma <small>(Marketing manegar)</small><br></br> 8527130867, 6006527627
                                        </li>
                                        <li style={{width:"100%"}}>
                                            <span>Email:</span>
                                            help@shivayadventure.com
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cs-map">

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3604.0896396695957!2d81.89155487538848!3d25.401809877579833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDI0JzA2LjUiTiA4McKwNTMnMzguOSJF!5e0!3m2!1sen!2sin!4v1732727361450!5m2!1sen!2sin"
                                        height="400" style={{ "border": "0" }} allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Layouts>

        </>

    )
}

export default Home