import React, { useState } from "react";
import Layouts from "./Layouts";
import SubHeader from "../components/SubHeader";
import banner from "../images/banner1.png";
import { toast } from "react-toastify";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");  // New state for success message
  const [errorMessage, setErrorMessage] = useState("");  // New state for error message

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSuccessMessage("");  // Clear success message on form validation failure
      setErrorMessage("");  // Clear error message on form validation failure
    } else {
      setErrors({});
      try {
        const response = await fetch("https://api.shivayadventure.com/api/addContact", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (response.ok) {
          toast.success("Form submitted successfully!")
          setSuccessMessage("Form submitted successfully!");  // Show success message
          setFormData({ name: "", email: "", phone: "", message: "" });
        } else {
          setErrorMessage(data.error || "Something went wrong!");  // Show error message if API fails
        }
      } catch (error) {
        setErrorMessage("Error submitting form: " + error.message);  // Show error message on failure
        setSuccessMessage("");  // Clear success message on error
      }
    }
  };

  return (
    <>
      <Layouts>
        <SubHeader banner={banner} heading="Contact Us" />

        <section className="contact-from-section spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>Contact Us By Email!</h2>
                  <p>
                    Fill out the form below to receive a free and confidential
                    initial consultation.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
              {successMessage && <p className="success-text">{successMessage}</p>}
              {errorMessage && <p className="error-text">{errorMessage}</p>}
                <form
                  onSubmit={handleSubmit}
                  className="comment-form contact-form"
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && <p className="error-text">{errors.name}</p>}
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && <p className="error-text">{errors.email}</p>}
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && <p className="error-text">{errors.phone}</p>}
                    </div>
                    <div className="col-lg-12 text-center">
                      <textarea
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && <p className="error-text">{errors.message}</p>}
                    </div>
                    <div className="clear"></div>
                    <div className="col-lg-12">
                      <button type="submit" className="site-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
               
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section spad contact-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>Feel free to reach out </h2>
                  <p>Get directions to our event center</p>
                </div>
                <div className="cs-text">
                <div className="ct-address">
                                        <span>Address:</span>
                                        <p>Someshwar ghat near by Delhi public school naini prayagraj. </p>
                                    </div>
                  <ul>
                                        <li>
                                            <span>Phone:</span>
                                            Ashutosh Srivastava <small>(Manegar)</small><br></br> 8576956055<br></br>
                                            Niraj Sharma <small>(Marketing manegar)</small><br></br> 8527130867, 6006527627
                                        </li>
                                        <li style={{width:"100%"}}>
                                            <span>Email:</span>
                                            help@shivayadventure.com
                                        </li>
                                    </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cs-map">
                <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3604.0896396695957!2d81.89155487538848!3d25.401809877579833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDI0JzA2LjUiTiA4McKwNTMnMzguOSJF!5e0!3m2!1sen!2sin!4v1732727361450!5m2!1sen!2sin"
                                        height="400" style={{ "border": "0" }} allowfullscreen=""></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
};

export default Contact;
